import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayForm from "@clayui/form";

import { Formik } from "formik";
import { FormControl } from "../../../../../../common-ui-modules";
import * as Yup from "yup";
import {
    getInitialValues,
    addressValuesReceived,
    getJobTitlesList,
    getStateVo,
    getProfileBuilderData,
    fetchStates,
    getUserInfoVo,
    stateVOReceived,
    // getTimezoneList,
    // getTimezoneValueList,
} from "../../../../../../store/Registration/profile-builder/slice";
import {
    getCountryList,
    getCountryValueList,
} from "../../../../../../store/Registration/registration/slice";
import { MAX_40, MIN_2, POSTAL_CODE_REGEX, SPECIAL_CHARS } from "../../constants";
import { MAX_255 } from "../../../pages/my-profile/UserInformation/constants";
import { checkIfStatePresent } from "../../../../../../utils/utils";
import { IS_PUBLIC } from "../../../../../../store/Registration/common/constants";

const Form6 = React.forwardRef((props, sixthFormRef) => {
    const dispatch = useAppDispatch();
    const [initialFormObj, setInitialFormObj] = useState({});
    const [isOptional, setOptional] = useState(false);

    const initialObj = useAppSelector(getInitialValues);

    const profileData = useAppSelector(getProfileBuilderData);
    const userInfo = useAppSelector(getUserInfoVo);
    // const timezoneOptions =useSelector(getTimezoneList)
    // const timezoneValuelist =useSelector(getTimezoneValueList)
    // const timezoneInfo="Your preferred timezone will be used for case routing and correspondence with our support teams"

    let stateOptions: any = [];
    stateOptions = useAppSelector(getStateVo);

    let jobTitles: any = [];
    jobTitles = useAppSelector(getJobTitlesList);

    const countryOptions = useAppSelector(getCountryList);
    const countryOptionValues = useAppSelector(getCountryValueList);

    useEffect(() => {
        const state = initialObj.addressValues.state;
        sixthFormRef.current.setFieldValue("phone", initialObj.addressValues.phone);
        sixthFormRef.current.setFieldValue("companyId", initialObj.addressValues.companyId);
        sixthFormRef.current.setFieldValue("companyName", initialObj.addressValues.companyName);
        // initialObj.addressValues.timezone?sixthFormRef.current.setFieldValue("timezone", initialObj.addressValues.timezone):sixthFormRef.current.setFieldValue("timezone", "");
        sixthFormRef.current.setFieldValue("address", initialObj.addressValues.address);
        sixthFormRef.current.setFieldValue("address2", initialObj.addressValues.address2);
        sixthFormRef.current.setFieldValue("city", initialObj.addressValues.city);
        sixthFormRef.current.setFieldValue("zipcode", initialObj.addressValues.zipcode);
        sixthFormRef.current.setFieldValue(
            "state",
            checkIfStatePresent(stateOptions, state) ? state : "",
        );
        sixthFormRef.current.setFieldValue("country", initialObj.addressValues.country);
        sixthFormRef.current.setFieldValue("jobTitle", userInfo.jobTitle);
        sixthFormRef.current.setFieldValue("website", initialObj.addressValues.website);
        sixthFormRef.current.setFieldValue("tapCompanyName", initialObj.addressValues.companyId == 5000000 ? initialObj.addressValues.tapCompanyName : "");
    }, [initialObj]);

    useEffect(() => {
        if (initialObj?.addressValues?.country?.toString() !== "186") {
            setOptional(true);
        } else {
            setOptional(false);
        }
    }, [initialObj.addressValues.country]);

    useEffect(() => {
        if (IS_PUBLIC) {
            const changeCountryTimeOut = setTimeout(() => {
                    sixthFormRef.current.setFieldValue("country", initialObj.addressValues.country);
            }, 0);
    
            return () => {
                clearTimeout(changeCountryTimeOut)
            }
        }
    }, [stateOptions]);

    useEffect(() => {
        let obj = {};
        if (profileData[5].data) {
            obj = { ...profileData[5].data };
        } else {
            obj = { broadcomcontact: "", jobTitle: userInfo.jobTitle,  ...initialObj.addressValues };
        }
        setInitialFormObj(obj);
    }, []);

    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const websiteRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;
    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .required("*Phone Number is required")
            .min(8, "A minimum of 8 character is required")
            .max(40, MAX_40)
            .matches(phoneRegExp, "Phone number is not valid"),
        address: Yup.string().required("*Address is required").min(2, MIN_2).max(255, MAX_255),
        address2: Yup.string()
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ0-9.,\s]+$/, SPECIAL_CHARS),
        city: Yup.string()
            .required("*City is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ\s]+$/, "Numbers & special characters are not allowed"),
        zipcode: Yup.string()
            .required("*Zip/Postal Code is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(POSTAL_CODE_REGEX, SPECIAL_CHARS),
        country: Yup.string()
            .oneOf(countryOptionValues, "Country is required")
            .required("Country is required"),
        companyName: Yup.string().required("*Company is required"),
        ...((initialObj.company?.fieldType === "select" && initialObj.addressValues.companyId == 5000000) && {tapCompanyName: Yup.string().required("*Company Name is required")}),
        companyId: Yup.string().required("*Company is required"),
        ...(initialObj.addressValues.newTapDomain && {website: Yup.string().nullable().required("*Website is required").matches(websiteRegExp, "Website is not valid")}),
        // timezone: Yup.string()
        // .oneOf(timezoneValuelist, "*Timezone is required")
        // .required("*Timezone is required"),
    });

    const handleCompanyChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ companyName: sixthFormRef.current.values.companyName }));
    };
    const handleTapCompanyChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ tapCompanyName: sixthFormRef.current.values.tapCompanyName }));
    };
    const handleContactChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ phone: sixthFormRef.current.values.phone }));
    };
    const handleWebsiteChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ website: sixthFormRef.current.values.website }));
    };
    const handleAddressChange = (value, formik, line) => {
        formik.handleBlur(value);
        if (line === 2) {
            dispatch(addressValuesReceived({ address2: sixthFormRef.current.values.address2 }));
        } else {
            dispatch(addressValuesReceived({ address: sixthFormRef.current.values.address }));
        }
    };
    const handleZipChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ zipcode: sixthFormRef.current.values.zipcode }));
    };
    const handleCityChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ city: sixthFormRef.current.values.city }));
    };
    const handleStateChange = (e, formik) => {
        formik.handleChange(e);
        sixthFormRef.current.setFieldValue("state", e.currentTarget.value);
        sixthFormRef.current.values["state"] = e.currentTarget.value;
        dispatch(addressValuesReceived({ state: sixthFormRef.current.values.state }));
    };
    const handleCompanySelectChange = (e, formik) => {
        formik.handleChange(e);
        sixthFormRef.current.setFieldValue("companyId", e.currentTarget.value);
        sixthFormRef.current.values.companyId = e.currentTarget.value;
        sixthFormRef.current.values.companyName =
            e.currentTarget.options[e.currentTarget.selectedIndex].text;
        dispatch(addressValuesReceived({ companyName: sixthFormRef.current.values.companyName }));
        dispatch(addressValuesReceived({ companyId: sixthFormRef.current.values.companyId }));
    };
    const handleJobTitleChange = (e) => {
        sixthFormRef.current.setFieldValue("jobTitle", e.currentTarget.value);
        sixthFormRef.current.values.jobTitle = e.currentTarget.value;
    };
    const handleOnChangeCountry = (e, formik) => {
        dispatch(stateVOReceived([{ label: "Select State", value: "" }]));
        formik.handleChange(e);
        sixthFormRef.current.setFieldValue("stateName", "");
        sixthFormRef.current.setFieldValue("country", e.currentTarget.value);
        sixthFormRef.current.setFieldValue(
            "countryName",
            e.currentTarget.options[e.currentTarget.selectedIndex].text,
        );
        dispatch(addressValuesReceived({ country: e.currentTarget.value }));
        dispatch(
            addressValuesReceived({
                countryName: e.currentTarget.options[e.currentTarget.selectedIndex].text,
            }),
        );
        dispatch(fetchStates(e.currentTarget.value));

        const getUserCountryVal = e.currentTarget.value;

        if (getUserCountryVal.toString() !== "186") {
            setOptional(true);
        } else {
            setOptional(false);
        }
    };

    // const handleOnChangeTimezone = (e, formik) => { 
    //     sixthFormRef.current.setFieldValue("timezone", e.currentTarget.value);
    //     dispatch(addressValuesReceived({ timezone: e.currentTarget.value }));
    // };

    const validateStateIfPresent = (value) => {
        let errors = "";
        if (
            (stateOptions.length > 1 && !value && !isOptional) ||
            (stateOptions.length > 1 &&
                value &&
                !isOptional &&
                !stateOptions.map((i) => i.value).includes(value))
        ) {
            errors = "State is required.";
        }
        return errors;
    };
    return (
        <React.Fragment>
            <Formik
                innerRef={sixthFormRef}
                enableReinitialize={"true"}
                destroyOnUnmount={"false"}
                initialValues={initialFormObj}
                validationSchema={validationSchema}
                onSubmit={() => {
                    /**/
                }}
            >
                {(formik) => (
                    <ClayForm onSubmit={formik.handleSubmit}>
                        {initialObj &&
                        initialObj.company &&
                        initialObj.company.fieldType === "select" ? (
                            <FormControl
                                control="select"
                                name="companyId"
                                label="Company"
                                options={initialObj.company.options}
                                onChange={(e) => handleCompanySelectChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        ) : (
                            <FormControl
                                control="text"
                                name="companyName"
                                label="Company"
                                onBlur={(e) => handleCompanyChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        )}
                        {initialObj.company?.fieldType === "select" && initialObj.addressValues.companyId == 5000000 ? <FormControl
                                control="text"
                                name="tapCompanyName"
                                label="Company Name"
                                onBlur={(e) => handleTapCompanyChange(e, formik)}
                            /> : ""}
                        {initialObj.addressValues.newTapDomain ? 
                        <FormControl
                            control="text"
                            type="text"
                            name="website"
                            label="Website"
                            onBlur={(e) => handleWebsiteChange(e, formik)}
                            autoComplete={"off"}
                            placeholder="Enter the website"
                        />: ""}
                        {/* <FormControl
                            control="select"
                            name="timezone"
                            label={"Preferred Timezone"}
                            autoComplete={"off"}
                            options={timezoneOptions}
                            onChange={(e) => handleOnChangeTimezone(e, formik)}
                            showInfo={true}
                            infotext={timezoneInfo}
                        /> */}
                        <FormControl
                            control="text"
                            type="text"
                            name="phone"
                            label="Phone Number"
                            autoComplete={"off"}
                            placeholder="Enter the contact number"
                            onBlur={(e) => handleContactChange(e, formik)}
                        />
                        <FormControl
                            control="text"
                            type="text"
                            name="address"
                            label="Address Line 1"
                            autoComplete={"off"}
                            placeholder="Enter the address line 1"
                            onBlur={(e) => handleAddressChange(e, formik)}
                        />

                        <small>Optional</small>
                        <FormControl
                            control="text"
                            type="text"
                            name="address2"
                            label="Address Line 2"
                            autoComplete={"off"}
                            placeholder="Enter the address line 2"
                            onBlur={(e) => handleAddressChange(e, formik, 2)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="city"
                            label="City"
                            autoComplete={"off"}
                            placeholder="Enter the city name"
                            onBlur={(e) => handleCityChange(e, formik)}
                        />
                        <FormControl
                            control="select"
                            name="country"
                            label="Country"
                            options={countryOptions}
                            onChange={(e) => handleOnChangeCountry(e, formik)}
                            onBlur={() => formik.setFieldTouched("country", true)}
                        />
                        {stateOptions.length > 1 && (
                            <FormControl
                                control="select"
                                name="state"
                                label="State"
                                autoComplete={"off"}
                                options={stateOptions}
                                validate={validateStateIfPresent}
                                onChange={(e) => handleStateChange(e, formik)}
                            />
                        )}
                        <FormControl
                            control="text"
                            type="text"
                            name="zipcode"
                            label="Zip/Postal Code"
                            autoComplete={"off"}
                            placeholder="Enter the zip/postal code"
                            onBlur={(e) => handleZipChange(e, formik)}
                        />
                        <small>Optional</small>
                        <FormControl
                            control="select"
                            name="jobTitle"
                            label="Job Title"
                            autoComplete={"off"}
                            options={jobTitles}
                            onChange={handleJobTitleChange}
                        />
                    </ClayForm>
                )}
            </Formik>
        </React.Fragment>
    );
});
Form6.displayName = "Form6";

export default Form6;
